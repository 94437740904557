"use client"
import { usePathname } from "next/navigation"

export default function Footer() {
  const pathname = usePathname()
  return (
    <footer>
      <div className="footer"></div>
      {(pathname === "/" || pathname === "/roadmap") && (
        <>
          {pathname === "/" && (
            <>
              <p className="footer__intro">
                Paal Supporters & <i>Partners</i>
              </p>
              <figure className="footer__supporters">
                <img src="images/surporter-01.png" alt="" data-light />
                <img src="images/surporter-01Dark.png" alt="" data-dark />
                <img src="images/surporter-02.png" alt="" data-light />
                <img src="images/surporter-02Dark.png" alt="" data-dark />
                <img src="images/surporter-03.png" alt="" />
                <img src="images/surporter-04.png" alt="" data-light />
                <img src="images/surporter-04Dark.png" alt="" data-dark />
                <img src="images/surporter-05.png" alt="" data-light />
                <img src="images/surporter-05Dark.png" alt="" data-dark />
              </figure>
            </>
          )}
          <div className="footer__ad">
            <img src="images/footerBg.png" alt="" data-desktop />
            <img src="images/MfooterBg.png" alt="" data-mobile />
            <img src="images/logo02.png" alt="" />
            <h2>
              Sign up for <i>Paal AI Mobile</i> App Waitlist
            </h2>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLScOiyRHzxs2WejFBQxUGGiTF8iLV0sBq0MkebXzALHMDnJHYQ/viewform?usp=send_form"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join Waitlist - <i>for free</i>
            </a>
          </div>
        </>
      )}
      <div className="footer__footer">
        <a href="#home" className="footer__logo">
          <img src="images/logo.png" alt="" />
          <h1> PAAL AI</h1>
        </a>
        <div className="footer__links">
          <div className="footer__socials">
            <a href="https://www.bitmart.com/trade/en-US?symbol=PAAL_USDT" target="_blank" rel="noopener noreferrer">
              <img src="images/social-01.png" alt="" />
            </a>
            <a
              href="https://www.geckoterminal.com/eth/pools/0x2a6c340bcbb0a79d3deecd3bc5cbc2605ea9259f"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="images/social-02.png" alt="" />
            </a>
            <a href="https://github.com/" target="_blank" rel="noopener noreferrer">
              <img src="images/social-03.png" alt="" data-invert />
            </a>
            <a href="https://twitter.com/PaalMind" target="_blank" rel="noopener noreferrer">
              <img src="images/social-04.png" alt="" data-invert />
            </a>
            <a href="https://t.me/paal_ai" target="_blank" rel="noopener noreferrer">
              <img src="images/social-05.png" alt="" data-invert />
            </a>
            <a href="https://discord.gg/paalai" target="_blank" rel="noopener noreferrer">
              <img src="images/social-06.png" alt="" data-invert />
            </a>
          </div>
          <p>
            You can email <a href="mailto:support@paal.ai">support@paal.ai</a> to provide feedback for support requests.
          </p>
          <div className="footer__policies">
            <a href="https://docs.paal.ai/policies/acceptable-use-policy" target="_blank" rel="noopener noreferrer">
              Acceptable Use Policy
            </a>
            <span style={{ color: "white" }}> |</span>
            <a href="https://docs.paal.ai/policies/cookie-policy" target="_blank" rel="noopener noreferrer">
              Cookie Policy
            </a>
            <span style={{ color: "white" }}> |</span>
            <a href="https://docs.paal.ai/policies/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            <span style={{ color: "white" }}> |</span>
            <a href="https://docs.paal.ai/policies/terms-of-service" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>
          </div>
        </div>
      </div>
      <figure className="footer__end">
        <p> © Paal AI 2024</p>
      </figure>
    </footer>
  )
}
