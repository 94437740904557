import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/widget.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/fonts/type.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/paal_favicon.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/index.scss");
